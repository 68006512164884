import React, { useRef, useEffect, useState } from 'react';
import './matrix.css'; // Import CSS file for styles

const MatrixEffect = ({ duration, textColor }) => {
  const canvasRef = useRef(null);
  const [fade, setFade] = useState(false);
  const [disableMatrix, setDisableMatrix] = useState(false);
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        setFade(true); // Trigger the fade-out effect after duration
      }, duration);

      return () => {
        clearTimeout(timer); // Clear the timer on component unmount
      };
    }
  }, [duration], [textColor]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (!canvas || !context) return;

    context.globalCompositeOperation = 'lighter';
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const generateRandomLetters = () => {
      const charCode = Math.floor(Math.random() * (0x042F - 0x0410 + 1)) + 0x0410; // Russian Cyrillic letters range
      return String.fromCharCode(charCode);
    };

    const textArray = [];
    const rows = Math.floor(window.innerHeight / 20); // Adjust the row height as needed
    const lettersPerRow = 5; // Increase the number of letters per row

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < lettersPerRow; j++) {
        const text = {
          x: Math.floor(Math.random() * window.innerWidth),
          y: -i * 20, // Start from the top of the canvas
          fontSize: Math.floor(Math.random() * 10) + 5, // Adjust font size here
          speed: Math.floor(Math.random() * 10) + 5, // Increase speed here
          letter: generateRandomLetters(),
        };
        textArray.push(text);
      }
    }



    const draw = () => {
      const currentTime = Date.now();
      const elapsedTime = 500;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.shadowOffsetX = context.shadowOffsetY = 0;
      context.shadowBlur = 20;
      context.shadowColor = '#fff';

      let opacity = 1;

      if (fade) {
        const fadeOutDuration = 200; // Adjust this duration value for the fade-out effect

        if (elapsedTime >= fadeOutDuration) {
          opacity = 0;
          setDisableMatrix(true); // Disable the matrix component after fade-out
        } else {
          const timeLeft = fadeOutDuration - elapsedTime;
          const fadeTime = 100; // Time taken to fade from full opacity to zero

          // Calculate opacity based on time remaining
          opacity = timeLeft > fadeTime ? 1 : (timeLeft / fadeTime);
        }
      }


      for (let i = 0; i < textArray.length; i++) {
        context.globalAlpha = opacity; // Set opacity for text
        context.font = `${textArray[i].fontSize}px MatrixCode`;
        context.textBaseline = 'top';
        context.textAlign = 'center';
        context.fillStyle = textColor ? textColor : '#00FF00'; // Use received textColor or default value

        context.fillText(
          textArray[i].letter,
          textArray[i].x,
          textArray[i].y >= window.innerHeight ? 0 : textArray[i].y
        );

        textArray[i].y += textArray[i].speed;
        if (textArray[i].y >= window.innerHeight) {
          textArray[i].x = Math.floor(Math.random() * window.innerWidth);
          textArray[i].y = 0;
          textArray[i].fontSize = Math.floor(Math.random() * 10) + 5;
          textArray[i].speed = Math.floor(Math.random() * 10) + 5;
          textArray[i].letter = generateRandomLetters();
        }
      }

      if (opacity > 0) {
        requestAnimationFrame(draw);
      }
    };

    if (!disableMatrix) {
      draw(); // Start the animation only if matrix is not disabled
    }

    return () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [fade, disableMatrix]);

  return (
    <canvas
      ref={canvasRef}
      className={`matrix-canvas ${fade ? 'fade-out' : ''}`}
      style={{ position: 'absolute', top: 0, left: 0, opacity: fade ? 0 : 1 }}
    />
  );
};

export default MatrixEffect;
