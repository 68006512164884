import React, { useState, useEffect, useRef } from "react";
import ProfileIcon from "./components/profileimg/profileimg";
import Typewriter from "./components/Typewriter/Typewriter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import imageSrc from "./imgs/2.png"; // Replace 'yourImage.png' with your image file path
import MatrixEffect from "./components/matrix/MatrixEffect";

const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [inputVisible, setInputVisible] = useState(false); // State to manage input visibility
  const [buttonClicked, setButtonClicked] = useState(false); // State to track button click
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState({ role: null, content: null });
  const [chatHistory, setChatHistory] = useState({});
  const [currentTitle, setCurrentTitle] = useState(null);
  const title = currentTitle ? currentTitle.substring(0, 1) : null;
  const [rows, setRows] = useState(1);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const textareaLineHeight = 24; // Adjust this based on your textarea's line-height
      const minHeight = 1; // Minimum number of rows

      textarea.style.height = `${minHeight * textareaLineHeight}px`;

      const calcRows = Math.floor(textarea.scrollHeight / textareaLineHeight);
      textarea.rows = calcRows;
    }
  }, [value]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Limiting input to 300 characters
    if (inputValue.length <= 300) {
      setValue(inputValue);
    }
  };


  const [assistantMessage, setAssistantMessage] = useState(""); // State to hold the assistant message


  const [fadeOut, setFadeOut] = useState(false);
  const handleTransitionEnd = (event) => {
    if (event.propertyName === "opacity") {
      // Assuming .img1 and .img2 have the fade-out class applied
      setFadeOut(true); // Trigger the fade-out effect for .img1 and .img2
    }
  };

  useEffect(() => {
    const img1Button = document.querySelector(".img1");
    const img2Button = document.querySelector(".img2");

    if (img1Button && img2Button) {
      img1Button.addEventListener("transitionend", handleTransitionEnd);
      img2Button.addEventListener("transitionend", handleTransitionEnd);
    }

    return () => {
      if (img1Button && img2Button) {
        img1Button.removeEventListener("transitionend", handleTransitionEnd);
        img2Button.removeEventListener("transitionend", handleTransitionEnd);
      }
    };
  }, []); // Run this effect once on mount

  const [matrixVisible, setMatrixVisible] = useState(false);
  const [textColor, setTextColor] = useState("#00FF00"); // State to track text color
  const handleButtonClick = (color) => {
    setMatrixVisible(true);
    if (color === "blue") {
      setTextColor("blue");
    } else if (color === "red") {
      setTextColor("red");
    }
  };

  const triggerFadeOut = () => {
    setFadeOut(true);
    document.body.classList.add("new-background-class");
    setTimeout(() => {
      //setInputVisible(true);
    }, 3000);
  };

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const createNewChat = () => {
    setMessage({ role: null, content: null });
    setValue("");
    setCurrentTitle(null);
  };

  const handleClick = (createNewChat) => {
    setMessage({ role: null, content: null });
    setValue("");
  };

  // Update the useEffect for chatHistory updates
  useEffect(() => {
    const truncatedChatHistory = {};
    Object.keys(chatHistory).forEach((key) => {
      const truncatedKey = key.substring(0, 20);
      truncatedChatHistory[truncatedKey] = chatHistory[key];
    });
    setChatHistory(truncatedChatHistory);
  }, []); // Update only when chatHistory changes

  const sendMessage = async () => {
    if (value.trim() !== "") {
      setValue(""); // Clear input
      const title = currentTitle || value;
      const newMessage = {
        role: "user",
        content: value,
      };

      try {
        const options = {
          method: "POST",
          body: JSON.stringify({
            message: value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        };
        setLoading(true); // Set loading to true before making the API call

        const response = await fetch(
          "https://34.162.235.201:1024/completions",
          options
        );

        const data = await response.json();
        console.log(data);

        setChatHistory((prevChatHistory) => {
          const updatedChatHistory = { ...prevChatHistory };
          const chatArray = updatedChatHistory[title] || [];

          const choiceMessage =
            data.choices && data.choices.length > 0
              ? data.choices[0].message
              : null;

          setAssistantMessage(choiceMessage);

          const updatedArray = [
            ...chatArray,
            newMessage,
            choiceMessage ? choiceMessage : { role: null, content: null },
          ];

          updatedChatHistory[title] = updatedArray;
          return updatedChatHistory;
        });

        setCurrentTitle(title);
      } catch (error) {
        alert("Error occurred while sending message " + error);
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };


  const currentChat = chatHistory[currentTitle] || [];
  const uniqueTitles = Object.keys(chatHistory);

  return (
    <div className="app">
      {/* Sidebar open/close button */}
      {!sidebarOpen && (
        <button className={`open-btn`} onClick={handleToggleSidebar}>
          <FontAwesomeIcon icon={faAnglesRight} className="arrow-icon" />
        </button>
      )}
      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <div className="sidebar-content">
          <button className="newchatbtn" onClick={() => {
            window.location.href = "https://chat.vgony.tech";
          }}>
            New Chat
          </button>

          {/* Close button */}
          {sidebarOpen && (
            <button className={`close-btn`} onClick={handleToggleSidebar}>
              <FontAwesomeIcon icon={faAnglesLeft} className="arrow-icon" />
            </button>
          )}

          <ul className="history">
            {uniqueTitles?.map((uniqueTitle, index) => (
              <li key={index} onClick={() => handleClick(uniqueTitle)}>
                {uniqueTitle}
              </li>
            ))}
          </ul>
          <nav>
            <p className="nav-footer">Made by V</p>
          </nav>
        </div>
      </div>
      <section className="main">
        {/* Other content... */}
        {matrixVisible && (
          <div className="matrix-container">
            {matrixVisible && (
              <MatrixEffect duration={1000} textColor={textColor} />
            )}{" "}
            {/* Pass textColor prop */}
          </div>
        )}
        {!currentTitle ? <h1>Mind of Agony</h1> : <h1>(VPT)</h1>}
        <ul className="pills">
          <a
            className={`img1 ${fadeOut ? "fade-out" : ""}`}
            onClick={() => {
              handleButtonClick("blue");
              triggerFadeOut()
              setTimeout(() => {
                window.location.href = "https://watch.vgony.tech/?channel=documentary";
              }, 3300);
            }}
          >
            <img
              src="https://imgur.com/tBHG8XG.gif"
              alt=""
              style={{ maxWidth: "95px", maxHeight: "95px" }}
            />
          </a>
          <a
            className={`img2 ${fadeOut ? "fade-out" : ""}`}
            onClick={() => {
              handleButtonClick("red");
              triggerFadeOut();
              setTimeout(() => {
                window.location.href = "https://chat.vgony.tech";
              }, 3300);
            }}
          >
            <img
              src="https://imgur.com/csKLcX8.gif"
              alt=""
              style={{ maxWidth: "108px", maxHeight: "100px" }}
            />
          </a>
          {fadeOut ? null : <p>{"Choose Your Path"} </p>}
        </ul>

        <ul className="feed">
          {currentChat.map((chatMessage, index) => (
            <li key={index} className="chat-message">
              <div className="message-content">
                <div className="profile-icon">
                  <ProfileIcon role={chatMessage.role} />
                </div>
                {/* Conditionally render 'You' or 'Morpheus' */}
                {chatMessage.role === "user" ? (
                  <p className="role">You</p>
                ) : (
                  <p className="role" style={{ marginTop: "0" }}>
                    Agent V
                  </p>
                )}
                {/* Conditionally apply the typewriter effect */}
                {chatMessage.role === "assistant" && (
                  <Typewriter text={chatMessage.content} />
                )}
                {chatMessage.role !== "assistant" && (
                  <p>{chatMessage.content}</p>
                )}
              </div>
            </li>
          ))}
        </ul>

        <div className="bottom-section">
          {inputVisible && (
            <div className="input-container">
              <textarea
                id="inputbox"
                value={value}
                onChange={handleInputChange}
                ref={textareaRef}
                id="yourTextarea"
                placeholder={"Say something..."}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default behavior on Enter key
                    sendMessage();
                  }
                }}
                style={{
                  maxWidth: "100%", // Ensure the textarea doesn't exceed its container width
                  maxHeight: "100%", // Limit the maximum height
                  resize: "none", // Disable textarea resizing
                }}
              />
              <div id="submit" href="Send message" onClick={sendMessage}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>

              <p className="info">designed for immortal beings</p>
              <div className="image-container">
                <img
                  src={imageSrc}
                  alt=""
                  style={{ width: "145px", height: "55px" }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default App;
