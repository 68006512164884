import React, { useState, useEffect, useRef } from 'react';
import './Typewriter.css';

const Typewriter = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const requestRef = useRef();

  useEffect(() => {
    if (text) {
      let animationFrameId;
      const typingSpeed = 1000; // Adjust typing speed here

      const animateTyping = () => {
        setDisplayText(text.substring(0, currentIndex));
        setCurrentIndex(prevIndex => prevIndex + 1);
        const textLength = text.length;

        if (currentIndex < textLength) {
          animationFrameId = requestAnimationFrame(animateTyping);
        }
      };

      if (currentIndex < text.length) {
        requestRef.current = requestAnimationFrame(animateTyping);
      }

      return () => cancelAnimationFrame(animationFrameId);
    }
  }, [text, currentIndex]);

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return <span>{displayText}</span>;
};

export default Typewriter;
