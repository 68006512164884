import React from 'react';
import assistantImage from './pedulum.gif'
import '../../index.css'

const ProfileIcon = ({ role }) => {
  const imageSource = role === 'assistant' ? assistantImage : null;

  return (
    <div className="profile-icon">
      {imageSource && <img src={imageSource} alt={role} />}
    </div>
  );
};

export default ProfileIcon;
